/*!
 * AdminKit v3.0.2 (https://adminkit.io/)
 * Copyright 2021 Paul Laros
 * Copyright 2021 AdminKit
 * Licensed under MIT (https://github.com/adminkit/adminkit/blob/master/LICENSE)
 */

// Variables
@import "1-variables/app";

// Bootstrap
@import "~bootstrap-5/scss/functions";
@import "~bootstrap-5/scss/variables";
@import "~bootstrap-5/scss/mixins";
@import "~bootstrap-5/scss/utilities";
@import "~bootstrap-5/scss/root";
@import "~bootstrap-5/scss/reboot";
@import "~bootstrap-5/scss/type";
@import "~bootstrap-5/scss/images";
@import "~bootstrap-5/scss/containers";
@import "~bootstrap-5/scss/grid";
@import "~bootstrap-5/scss/tables";
@import "~bootstrap-5/scss/forms";
@import "~bootstrap-5/scss/buttons";
@import "~bootstrap-5/scss/transitions";
@import "~bootstrap-5/scss/dropdown";
@import "~bootstrap-5/scss/button-group";
@import "~bootstrap-5/scss/nav";
@import "~bootstrap-5/scss/navbar";
@import "~bootstrap-5/scss/card";
@import "~bootstrap-5/scss/badge";
@import "~bootstrap-5/scss/list-group";
@import "~bootstrap-5/scss/breadcrumb";
@import "~bootstrap-5/scss/alert";
@import "~bootstrap-5/scss/pagination";
@import "~bootstrap-5/scss/tooltip";
@import "~bootstrap-5/scss/close";
@import "~bootstrap-5/scss/helpers";
@import "~bootstrap-5/scss/utilities/api";

// Theme mixins
@import "2-mixins/button";

// Theme components
@import "3-components/avatar";
@import "3-components/buttons";
@import "3-components/card";
@import "3-components/chart";
@import "3-components/content";
@import "3-components/dropdown";
@import "3-components/feather";
@import "3-components/footer";
@import "3-components/hamburger";
@import "3-components/list-group";
@import "3-components/main";
@import "3-components/navbar";
@import "3-components/reboot";
@import "3-components/sidebar";
@import "3-components/sizing";
@import "3-components/stat";
@import "3-components/tables";
@import "3-components/type";
@import "3-components/wrapper";

// Theme utilities
@import "4-utilities/cursors";

// 3rd party plugins
@import "~jsvectormap/dist/css/jsvectormap";
@import "~simplebar/dist/simplebar";
@import "~flatpickr/dist/flatpickr";

// 3rd party plugin styles
@import "5-vendor/flatpickr";
@import "5-vendor/simplebar";

.timeline-separator {
    position: relative;
    height: 25px;
    margin-bottom: 25px;

    p {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%);
        padding-left: 10px;
        line-height: 1.7;

        &:before {
            content: "";
            position: absolute;
            height: 40px;
            width: 4px;
            left: -2px;
            top: -8px;
            border-radius: 2px;
            background-color: darken(#f5f7fb, 10%);
        }
    }
}

.input-group {
    .btn {
        &.dropdown-toggle {
            border-color: $gray-400;
        }
    }
}

.dropdown-menu {
    .dropdown-item {
        &.dropdown-item-active {
            color: #1e2125;
            background-color: $gray-200;
        }
    }
}
